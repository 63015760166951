import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import HeroSection from "../components/HeroSection";
import BIHero from "../images/business-intelligence/BI-hero.svg";
import DataIntegrations from "../components/BusinessIntelligence/DataIntegrations";
import SourcesAndTools from "../components/BusinessIntelligence/SourcesAndTools";
import Section from "../containers/Section";
import PricingCard from "../components/PricingCard";
import Reports from "../components/BusinessIntelligence/Reports";
import ReportsImg from "../images/business-intelligence/reports.svg";
import BottomCTA from "../components/BottomCta";
import AMCSection from "../components/BusinessIntelligence/AMCSection";
import AMCDiagram from "../images/business-intelligence/amc-diagram.svg";
import { IconType } from "../components/Icon";
import { useTranslation } from "gatsby-plugin-react-i18next";
import FormModal from "../components/FormModal";
import { PAGE } from "../constants/pages";
import { CTA_ID } from "../utils/analytics/constants";

interface BusinessIntelligenceProps {
  data: any;
}

const BusinessIntelligence: React.FC<BusinessIntelligenceProps> = ({
  data
}) => {
  const { t } = useTranslation();

  const pageData = data;

  const [activeDataConsultModal, setActiveDataConsultModal] = useState<{
    variant: string;
    isOpen: boolean;
  }>(null);

  const cta = {
    text: t("Book a data consult"),
    route: t("/demo")
  };

  const sourcesAndTools = [
    {
      title: t("Sources"),
      features: [
        {
          title: t("Amazon Advertising"),
          subItems: [
            t("Sponsored Ads"),
            t("Best Seller Rank"),
            t("Organic Rank"),
            t("Share of Voice"),
            t("Search Frequency Rank"),
            t("Editorials"),
            t("SP API"),
            t("Amazon Stream"),
            t("Amazon DSP")
          ]
        },
        { title: t("Walmart Advertising") },
        { title: t("Criteo Advertising") },
        { title: t("Citrus Advertising") },
        { title: t("Instacart Advertising") },
        { title: t("Perpetua") }
      ]
    },
    {
      title: t("Destinations"),
      features: [
        { title: t("Snowflake") },
        { title: t("Amazon Redshift") },
        { title: t("Google BigQuery") },
        { title: t("Microsoft SQL Server") },
        { title: t("PostgreSQL") },
        { title: t("+ The data visualization tool of your choice!") }
      ]
    }
  ];

  const pricingData = [
    {
      title: t("Starter"),
      displayedTitle: t("Bespoke Data Integrations"),
      price: t("$750"),
      taxes: t("one-time setup fee"),
      cta: {
        text: t("Start with Bespoke Data Integrations"),
        route: t("/demo")
      }
    },
    {
      title: t("Premium"),
      displayedTitle: t("Custom Data Integrations"),
      price: t("$250"),
      pricePerMonth: t("hr"),
      taxes: t("minimum 3 hrs of work"),
      cta: {
        text: t("Start with Custom Data Integrations"),
        route: t("/demo")
      }
    }
  ];
  const pricingDatReports = [
    {
      title: t("Starter"),
      displayedTitle: t("Bespoke Report"),
      price: t("$750"),
      taxes: t("Per Report"),
      cta: {
        text: t("Start with Bespoke Reports"),
      }
    },
    {
      title: t("Premium"),
      displayedTitle: t("Custom Reports"),
      price: t("$250"),
      pricePerMonth: t("hr"),
      taxes: t("minimum 3 hrs of work"),
      cta: {
        text: t("Start with Custom Reports"),
      }
    }
  ];

  const bottomCta = {
    title: t("Getting started is easy"),
    primaryButtonClassName: "",
    primaryButtonId: "",
    primaryButtonRoute: t("/demo"),
    primaryButtonText: t("Book a data consult")
  };

  const [isVisible, setIsVisible] = useState(false);
  const [height, setHeight] = useState(0);

  const listenToScroll = () => {
    let heightToHideFrom = 3550;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll < heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <Layout primaryButtonId={CTA_ID.getStartedNav.id}>
      <SEO
        title={t("eCommerce Business Intelligence (BI)")}
        description={t(
          "Perpetua’s eCommerce business intelligence and data analytics solutions empower brands and agencies to transform marketplace sales, retail, and advertising data into intelligible visualizations and reports, allowing business users to make smarter and timely decisions."
        )}
        image={`https://perpetua.io${pageData?.openGraphImage?.publicURL}`}
      />
      <HeroSection
        page={PAGE.BUSINESS_INTELLIGENCE}
        bgGradient="bg-white"
        gradientTitle={[t("Unlock"), t("the power of your eCommerce data")]}
        gradientPosition={1}
        h1Title={t("eCommerce Business intelligence (BI)")}
        dataConsultModal={setActiveDataConsultModal}
        description={t(
          "Data integrations, custom reporting, and Amazon Marketing Cloud solutions — a complete toolkit designed for more powerful, data-driven advertising."
        )}
        cta={cta}
        icon={IconType.data}
        imageReplacement={<BIHero className="w-full md:h-full h-1/2" />}
      />
      <DataIntegrations
        subTitle={t("Seamless Data Integrations")}
        gradientTitle={[t("Connect your data,"), t("your way")]}
        gradientPosition={2}
        description={t(
          "Say goodbye to data silos. Bring marketplace sales, retail, and advertising data together with beautiful dashboards in the tool of your choice."
        )}
      />
      <SourcesAndTools
        title={t(
          "Supported data sources, destinations and business intelligence tools"
        )}
        features={sourcesAndTools}
      />
      <Section>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 xl:w-1/2 mx-auto justify-center mb-10">
          {pricingData?.map((item, index) => {
            return (
              <PricingCard
                key={index}
                variant={item?.title?.toLowerCase()}
                data={item}
                page={PAGE.BUSINESS_INTELLIGENCE}
                dataConsultModal={setActiveDataConsultModal}
              />
            );
          })}
        </div>
        <div className="w-full lg:w-1/2 mx-auto">
          <p className="text-sm px-8 py-10 border text-gray-2-light border-gray-5-light bg-white rounded-lg shadow">
            {t(
              "Minimum $50/month for storage and query costs. If tables are greater than 100GB of data, $10/month for each additional 10GB."
            )}
          </p>
        </div>
      </Section>
      <Reports
        gradientTitle={[
          t("Illuminating reports,"),
          t("tailored to your business")
        ]}
        gradientTitlePosition={2}
        subTitle={t("Custom Reporting")}
        description={t(
          "We’ve built reporting infrastructure for the world’s most sophisticated brands and agencies — we know what it takes to put your eCommerce data to work. /n Get access to our library of bespoke templates or engage our team of business intelligence experts to design custom reports, visualizations and data displays for your team."
        )}
        image={<ReportsImg className="w-full md:h-full h-1/2" />}
      />

      <Section className="md:pt-20 md:pb-24">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 xl:w-1/2 mx-auto justify-center mb-10">
          {pricingDatReports?.map((item, index) => {
            return (
              <PricingCard
                key={index}
                variant={item?.title?.toLowerCase()}
                data={item}
                page={PAGE.BUSINESS_INTELLIGENCE}
                dataConsultModal={setActiveDataConsultModal}
              />
            );
          })}
        </div>
        <div className="w-full lg:w-1/2 mx-auto">
          <p className="text-sm px-8 py-10 border text-gray-2-light border-gray-5-light bg-white rounded-lg shadow">
            {t(
              "Minimum $50/month for storage and query costs. If tables are greater than 100GB of data, $10/month for each additional 10GB."
            )}
          </p>
        </div>
      </Section>
      <Section>
        <AMCSection
          title={t("Perpetua for Amazon Marketing Cloud (AMC)")}
          description={t(
            "Understand how your advertising efforts impact your customer journey. With Perpetua for AMC, you’ll get answers to meaningful questions like “how loyal are my customers?”, “what is the lifetime value of an Amazon customer?” and so much more."
          )}
          image={<AMCDiagram className="w-full" />}
          cta={{
            route: t("/amc-amazon-marketing-cloud/"),
            text: t("Learn more about Perpetua for Amazon Marketing Cloud")
          }}
        />
      </Section>

      <BottomCTA
        primaryButtonId={bottomCta?.primaryButtonId}
        title={bottomCta?.title}
        primaryButtonText={bottomCta?.primaryButtonText}
        primaryButtonClassName={bottomCta?.primaryButtonClassName}
        primaryButtonOnClick={() =>
          setActiveDataConsultModal({ variant: "", isOpen: true })
        }
      />
      {activeDataConsultModal?.isOpen && (
        <FormModal
          setActiveDataConsultModal={setActiveDataConsultModal}
          title={t("We just need a bit of info first")}
          data={activeDataConsultModal}
        />
      )}
    </Layout>
  );
};

export default BusinessIntelligence;

export const data = graphql`
  query {
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    openGraphImage: file(
      relativePath: { eq: "business-intelligence/BI-openGraph.png" }
    ) {
      publicURL
    }
  }
`;
